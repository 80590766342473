import React from 'react';
import Tetris from 'components/Tetris';

const TetrisGame = () => {
  return (
    <Tetris />
  );
};

export default TetrisGame
