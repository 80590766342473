import React from 'react';
import Tower from 'components/Tower';

const TowerGame = () => {
  return (
    <Tower />
  );
};

export default TowerGame
