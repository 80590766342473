import styled from 'styled-components';

export const StyledSongButton = styled.div`
  display: grid;
  box-sizing: border-box;
  margin: 0 0 20px 0;
  padding: 20px;
  width: 100%;
  border-radius: 20px;
  border: none;
  color: white;
  background: #333;
  font-family: Pixel, Arial, Helvetica, sans-serif;
  font-size: 1rem;
  outline: none;
  cursor: pointer;
`;
