import React from 'react';
import Snake from 'components/Snake';

const SnakeGame = () => {
  return (
    <Snake />
  );
};

export default SnakeGame
